@font-face {
  font-family: "Neutraface2-Text-Book";
  src: url("../../resources/Neutraface_2_text/Neutra2Text-Book.otf")
    format("OpenType");
}

@font-face {
  font-family: "Neutraface2-Text-Bold";
  src: url("../../resources/Neutraface_2_text/Neutra2Text-Bold.otf")
    format("OpenType");
}

@font-face {
  font-family: "Neutraface2-Text-Demi";
  src: url("../../resources/Neutraface_2_text/Neutra2Text-Demi.otf")
    format("OpenType");
}
