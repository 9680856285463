.react-select-container {
  width: 100%;
  max-width: 100%;
  outline: none !important;
}

.react-select__control {
  border: 1px solid #dfdfdf !important;
  outline: none !important;
}

.react-select__control--is-focused {
  box-shadow: none !important;
}

.react-select__value-container {
  font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
}

.react-select__multi-value {
  font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
  color: #333 !important;
  background-color: #fff !important;
  border: 1px solid #ccc !important;
  border-radius: 0 !important;
}

.react-select__multi-value:hover {
  background-color: #e6e6e6 !important;
  border-color: #adadad !important;
}

.react-select__multi-value__remove {
  opacity: 0.2;
}

.react-select__multi-value__remove:hover {
  opacity: 0.5;
  background-color: transparent !important;
  color: #000 !important;
  cursor: pointer !important;
  border-radius: 0 !important;
}

.react-select__menu {
  box-shadow: 5px 5px rgba(102, 102, 102, 0.1) !important;
  border-radius: 0 !important;
  left: 0;
  min-width: 175px;
  z-index: 1000;
  float: left;
  list-style: none;
  text-shadow: none;
  padding: 0;
  background-color: #fff;
  margin: 10px 0 0;
  border: 1px solid #eee;
  font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
  padding: 0;
}

.switch-job {
  border-radius: 6px !important;
  background: none !important;
  border: 1px solid rgb(169, 181, 187) !important;
}

.switch-job .react-switch-bg {
  width: 58px !important;
  margin: 0 -1px !important;
}
.switch-job .react-switch-handle {
  background: #FFFFFF !important;
  background-image: linear-gradient(180deg, rgba(255,255,255,0.12) 0%, rgba(0,0,0,0.12) 100%) !important;
  border-radius: 6px !important;
  top: 0 !important;
  height: 28px !important;
}

