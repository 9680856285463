.react-tabs {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.react-tabs__tab-list {
  background-color: #fff;
  display: flex;
  flex-direction: row;
  list-style-type: none;
  margin: 0px;
  padding-left: 0px;
}

.react-tabs__tab {
  display: flex;
  color: #909090;
  font-family: Helvetica;
  padding: 0 45px;
  font-size: 18px;
  font-weight: 500;
  height: 55px;
  align-items: center;
  border-left: 1px solid #dfdfdf;
  position: relative;
  cursor: pointer;
}

.react-tabs__tab:last-child {
  border-right: 1px solid #dfdfdf;
}

.react-tabs__tab.react-tabs__tab--selected {
  color: rgb(0, 66, 88);
}

.react-tabs__tab.react-tabs__tab--selected::after {
  content: ' ';
  width: 100%;
  height: 3px;
  background-color: rgb(247, 148, 30);
  position: absolute;
  bottom: -3px;
  right: 0px;
}

.react-tabs__tab-panel.react-tabs__tab-panel--selected {
  height: calc(100% - 70px);
}