body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  font-weight: 400;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
  font-family: "DIN";
  src: url(/static/media/DINMd___.d4cabfde.ttf) format("truetype");
}

@font-face {
  font-family: "DIN-Regular";
  src: url(/static/media/DINRG__.f94b7667.TTF) format("truetype");
}

@font-face {
  font-family: "DIN-Light";
  src: url(/static/media/DINLi___.7c6c5a2a.ttf) format("truetype");
}

@font-face {
  font-family: "Open sans-serif-Regular";
  src: url(/static/media/OpenSans-Regular.d7d5d458.ttf) format("truetype");
}

@font-face {
  font-family: "Open sans-serif-Semi-bold";
  src: url(/static/media/OpenSans-SemiBold.e1c83f94.ttf) format("truetype");
}

.auth0-lock-overlay {
  display: none;
}
.react-select-container {
  width: 100%;
  max-width: 100%;
  outline: none !important;
}

.react-select__control {
  border: 1px solid #dfdfdf !important;
  outline: none !important;
}

.react-select__control--is-focused {
  box-shadow: none !important;
}

.react-select__value-container {
  font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
}

.react-select__multi-value {
  font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
  color: #333 !important;
  background-color: #fff !important;
  border: 1px solid #ccc !important;
  border-radius: 0 !important;
}

.react-select__multi-value:hover {
  background-color: #e6e6e6 !important;
  border-color: #adadad !important;
}

.react-select__multi-value__remove {
  opacity: 0.2;
}

.react-select__multi-value__remove:hover {
  opacity: 0.5;
  background-color: transparent !important;
  color: #000 !important;
  cursor: pointer !important;
  border-radius: 0 !important;
}

.react-select__menu {
  box-shadow: 5px 5px rgba(102, 102, 102, 0.1) !important;
  border-radius: 0 !important;
  left: 0;
  min-width: 175px;
  z-index: 1000;
  float: left;
  list-style: none;
  text-shadow: none;
  padding: 0;
  background-color: #fff;
  margin: 10px 0 0;
  border: 1px solid #eee;
  font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
  padding: 0;
}

.switch-job {
  border-radius: 6px !important;
  background: none !important;
  border: 1px solid rgb(169, 181, 187) !important;
}

.switch-job .react-switch-bg {
  width: 58px !important;
  margin: 0 -1px !important;
}
.switch-job .react-switch-handle {
  background: #FFFFFF !important;
  background-image: linear-gradient(180deg, rgba(255,255,255,0.12) 0%, rgba(0,0,0,0.12) 100%) !important;
  border-radius: 6px !important;
  top: 0 !important;
  height: 28px !important;
}


.react-datepicker__input-container>input {
  position: relative;
  background: #fff;
  padding: 0 15px;
  margin-bottom: 15px;
  border: 1px solid #dfdfdf;
  height: 30px;
  width: 100%;
  border-radius: 6px !important;
  outline: none;
  box-sizing: border-box;
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 14px;
  color: #555;
}
.react-resizable-handle {
  background: none !important;
  border-right: 1px solid #eaeaea !important;
  height: 100% !important;
  right: -1px !important;
  cursor: col-resize !important;
}

.secondary > .react-resizable-handle{
  right: 1px !important;
}
.react-calendar {
  background-color: #f2f2f2;
  font-family: 'Source Sans Pro', sans-serif !important;
  font-size: 12px;
  font-weight: 400;
  color: #a5a4a5;
  margin-bottom: 30px;
}

.react-calendar__navigation__arrow,
.react-calendar__navigation__label {
  outline: none;
  border: none;
  width: 56px;
  height: 30px;
  text-align: center;
  cursor: pointer;
  background-color: transparent;
  color: #a5a4a5;
}

.react-calendar__navigation__arrow:hover,
.react-calendar__navigation__label:hover {
  background-color: #eee;
}

.react-calendar__month-view__weekdays__weekday {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 30px;
  text-align: center;
  margin-top: 10px;
  margin-bottom: 30px;
}

.react-calendar__tile {
  text-align: center;
  background: transparent;
  border: none;
  outline: none;
  height: 40px;
  margin-bottom: 20px;
}

.react-calendar__tile time {
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;
  border-radius: 50%;
  background: transparent;
}

.react-calendar__tile time:hover {
  background: #eee;
  color: #a5a4a5 !important;
  cursor: pointer;
}

.react-calendar__tile--active time {
  background: #fe965f;
  color: white;
}

.react-calendar__month-view__days__day--neighboringMonth time {
  color: #999;
}

/* .react-switch-bg {
  background-color: #f2f2f2 !important;
} */
.react-tabs {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.react-tabs__tab-list {
  background-color: #fff;
  display: flex;
  flex-direction: row;
  list-style-type: none;
  margin: 0px;
  padding-left: 0px;
}

.react-tabs__tab {
  display: flex;
  color: #909090;
  font-family: Helvetica;
  padding: 0 45px;
  font-size: 18px;
  font-weight: 500;
  height: 55px;
  align-items: center;
  border-left: 1px solid #dfdfdf;
  position: relative;
  cursor: pointer;
}

.react-tabs__tab:last-child {
  border-right: 1px solid #dfdfdf;
}

.react-tabs__tab.react-tabs__tab--selected {
  color: rgb(0, 66, 88);
}

.react-tabs__tab.react-tabs__tab--selected::after {
  content: ' ';
  width: 100%;
  height: 3px;
  background-color: rgb(247, 148, 30);
  position: absolute;
  bottom: -3px;
  right: 0px;
}

.react-tabs__tab-panel.react-tabs__tab-panel--selected {
  height: calc(100% - 70px);
}
@font-face {
  font-family: "Neutraface2-Text-Book";
  src: url(/static/media/Neutra2Text-Book.50d460d4.otf)
    format("OpenType");
}

@font-face {
  font-family: "Neutraface2-Text-Bold";
  src: url(/static/media/Neutra2Text-Bold.b2f1a2a1.otf)
    format("OpenType");
}

@font-face {
  font-family: "Neutraface2-Text-Demi";
  src: url(/static/media/Neutra2Text-Demi.ffd7e677.otf)
    format("OpenType");
}

