body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  font-weight: 400;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
  font-family: "DIN";
  src: url('./resources/DIN/DINMd___.ttf') format("truetype");
}

@font-face {
  font-family: "DIN-Regular";
  src: url('./resources/DIN/DINRG__.TTF') format("truetype");
}

@font-face {
  font-family: "DIN-Light";
  src: url('./resources/DIN/DINLi___.ttf') format("truetype");
}

@font-face {
  font-family: "Open sans-serif-Regular";
  src: url('./resources/Open_sans/OpenSans-Regular.ttf') format("truetype");
}

@font-face {
  font-family: "Open sans-serif-Semi-bold";
  src: url('./resources/Open_sans/OpenSans-SemiBold.ttf') format("truetype");
}

.auth0-lock-overlay {
  display: none;
}