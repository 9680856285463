.react-datepicker__input-container>input {
  position: relative;
  background: #fff;
  padding: 0 15px;
  margin-bottom: 15px;
  border: 1px solid #dfdfdf;
  height: 30px;
  width: 100%;
  border-radius: 6px !important;
  outline: none;
  box-sizing: border-box;
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 14px;
  color: #555;
}